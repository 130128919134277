import { IonButton, IonCol, IonIcon, IonInput, IonItem, IonRow, isPlatform } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';

import moment from 'moment';
import QRCodeCanvas from 'qrcode.react';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import AddToWallet from '../../components/addToWallet';
import {
  FieldError,
  NormalText,
  SmallText,
  Spacer,
  StrongText,
  Subtitle,
  Title,
} from '../../components/common';
import Layout from '../../components/layout';
import Modal from '../../components/modal';
import Loading from '../../components/spinner';
import api from '../../lib/api';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import {
  forwardTo,
  isDefined,
  isEmptyObject,
  isWebConfig,
  validateForm,
  validateProfileData,
} from '../../lib/utils';
import {
  getFaq,
  getProfile,
  getRewards,
  getTransactionHistory,
  getVouchers,
  sendVoucherCode,
  setModal,
} from '../../store/actions';
import KioskCodeGenerator from '../../components/kioskCode';

import './index.css';
import VoucherCodeInput from '../../components/voucherCodeInput';
import UnlockRewardItems from '../../components/unlockRewardItems';
import { Capacitor } from '@capacitor/core';
import MilestoneRewards from '../../components/milestoneRewards';

const isMobileIosWeb = () => Capacitor.getPlatform() === 'web' && isPlatform('ios') && isPlatform('mobile');

export const VoucherItem = ({ reward, id, className, action, __, onlyImage }) => {
  return (
    <div>
      <div style={{ backgroundImage: `url(${reward.image})` }} className={'voucher' + (className ? ' ' + className : '')} onClick={action ? () => action(id) : null}></div>
      {!onlyImage && (
        <div className="voucher-description">
          <SmallText>{__(reward.name)}</SmallText>
          <SmallText>{`${__('Expires')} ${moment(reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}`}</SmallText>
        </div>
      )}
    </div>
  );
};
export const getLabel = (item) => {
  let label = '';
  const defaultDescriptors = [
    {
      bl_id: '-1',
      label: 'Referral Bonus',
    },
    {
      bl_id: '-2',
      label: 'Sign up Bonus',
    },
    {
      bl_id: '-3',
      label: 'Refunded points',
    },
  ];
  const historyDescriptors = getConfig().history_descriptors || defaultDescriptors;

  const descriptors = historyDescriptors.filter((el) => el.bl_id === item.business_location_id);
  if (descriptors.length > 0) {
    label = descriptors[0].label;
  } else {
    let isPoints = item.is_points;
    let isStamps = item.is_stamps;
    let isVouchers = item.is_vouchers;
    let subLabel = '';
    if (Math.abs(item.stamp_power) === 1) {
      subLabel = `${isPoints ? 'Point' : isStamps ? 'Stamp' : isVouchers ? 'Voucher' : ''}`;
    } else {
      subLabel = `${isPoints ? 'Points' : isStamps ? 'Stamps' : isVouchers ? 'Vouchers' : ''}`;
    }
    if (item.stamp_power < 0) {
      label = `Redeemed ${subLabel}`;
    } else if (item.stamp_power > 0) {
      label = `Earned ${subLabel}`;
    }
  }
  return label;
};
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      loyaltyFaqModalOpened: false,
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
      stampCards: [],
      stampCardModalOpen: false,
      stampCardData: null,
      keyboardIsVisible: false
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  async componentDidMount() {

    this.props.dispatch(getRewards());
    this.props.dispatch(getTransactionHistory());
    const config = await api.getFrontEndAppConfig();

    const stampsPosition =
      config?.stamp_cards_json?.map((el) => Array(el.stamps_required).fill({})) || [];
    for (let i = 0; i < stampsPosition.length; i++) {
      for (let j = 0; j < stampsPosition[i].length; j++) {
        stampsPosition[i][j] = {
          top: this.getRandomNumber(-3, 3),
          left: this.getRandomNumber(-3, 3),
          rotate: this.getRandomNumber(-90, 90),
        };
      }
    }
    this.props.dispatch(getFaq());
    this.setState({ stampCards: config?.stamp_cards_json || [], stampsPosition });
    if (this.props?.location?.state?.openVoucherModal) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
    }
  };
  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  getNumberOfRowsAndColumns(stampNumber) {
    let data = {};
    if (stampNumber % 5 !== 0) {
      data.columns = 'repeat(4,65px)';
      data.rows = '65px';
    } else {
      data.columns = 'repeat(5,50px)';
      data.rows = '50px';
    }
    return data;
  }
  onRefreshHandler = (e) => {
    setTimeout(() => {
      this.props.dispatch(getProfile());
      this.props.dispatch(getTransactionHistory());
      this.props.dispatch(getRewards());
      this.props.dispatch(getVouchers());

      e.target.complete();
    }, 2000);
  };
  render() {
    const {
      __,
      profile,
      screenName,
      transactionHistory,
      faq,
      vouchers,
      qr_code,
      rewards
    } = this.props;
    const { loyaltyFaqModalOpened, voucherCode, selectedVoucher, keyboardIsVisible } = this.state;
    //  let voucherRes = (vouchers || []).filter((data) => {
    //   if (data.type === 1) {
    //     return data;
    //   }
    // });
    const valid = validateProfileData(profile).isValid;
    let voucherRes = vouchers || [];

    const { hasAddToAppleWallet, hasAddToGoogleWallet } = getConfig().flags;
    const points_value_percentage =
      isDefined(getConfig().api_config) && isDefined(getConfig().api_config.points_value_percentage)
        ? getConfig().api_config.points_value_percentage
        : 0;
    return (
      <Loading>
        <Layout
          withRefresher={true}
          onRefreshHandler={this.onRefreshHandler}
          color="transparent"
          headerWithTitle={true}
          title={__('Loyalty')}
          noPadding={true}
          showHamburger
        >
          <div className={`scrollable-y ${keyboardIsVisible ? 'keyboard-is-visible' : ''}`}>
            <div className="loyalty-header" style={{ backgroundImage: `url(${getConfig().general.loyaltyCardImage})` }} >
              <div className="loyalty-header-name">
                <Subtitle className="bold loyalty-name">{screenName}</Subtitle>
                <div className="loyalty-header-groups">
                  {profile?.group_tags && profile.group_tags.map((tag) => (
                    <SmallText>{tag}</SmallText>
                  ))}
                </div>
              </div>
              <div className="loyalty-header-content">
                <div>
                  {points_value_percentage == 0 && profile.available_balance == 0 ? (
                    <div></div>
                  ) : (
                    <div>
                      <Subtitle className="loyalty-points lowercase">
                        {profile.available_balance?.toLocaleString()} {__('pts')}{' '}
                        <IonIcon
                          onClick={() => {
                            this.setState({ loyaltyFaqModalOpened: true });
                          }}
                          icon={informationCircleOutline}
                        />
                      </Subtitle>
                      {Basket.calculatePointsAppliedPrice(profile.available_balance) > 0 && !getConfig().flags.removeLoyaltyPointsWorth && (
                        <SmallText className="loyalty-points-value">
                          {`${__('worth')} ${Basket.formatPrice(Basket.calculatePointsAppliedPrice(profile.available_balance))}`}
                        </SmallText>
                      )}

                    </div>
                  )}

                  <div>
                    <NormalText className="block">{__('Member since')}</NormalText>
                    <NormalText className="uppercase ">
                      {moment(profile.created_at).format('MM/YY')}
                    </NormalText>
                  </div>
                </div>

                <div style={{ flexDirection: 'column' }}>
                  <div className="qr-code">
                    {isDefined(profile.qr_code) && profile.qr_code !== '' ? (
                      <div className="qr-holder">
                        <QRCodeCanvas value={profile.qr_code} size={isWebConfig() ? 150 : 200} />
                      </div>
                    ) : (
                      <div>
                        <h5>{__('NO QR CODE')}</h5>
                      </div>
                    )}
                  </div>
                  {isDefined(profile.qr_code) &&
                    profile.qr_code !== '' &&
                    (hasAddToAppleWallet || hasAddToGoogleWallet) ? (
                    <AddToWallet />
                  ) : null}
                  {!isWebConfig() && getConfig().flags.hasKioskCodeGenerator && (
                    <>
                      {' '}
                      <KioskCodeGenerator />
                      <Spacer size={1} />
                    </>
                  )}
                </div>
              </div>
              {isWebConfig() && getConfig().flags.hasKioskCodeGenerator && (
                <>
                  <Spacer size={1} /> <KioskCodeGenerator />
                </>
              )}
            </div>

            <div
              onClick={() => {
                !valid && this.props.dispatch(setModal('isVerfiedModalOpen', true));
              }}
              className={`loyalty-validation-wrapper ${!valid && 'invalid'}`}
            >
              <NormalText>{valid ? __('Account verified') : __('Account not verified')}</NormalText>
            </div>

            {this.state.stampCards.length > 0 && (
              <div className="stamps-content">
                <Subtitle className="primary-color loyalty-heading">
                  {__('Your loyalty cards')}
                </Subtitle>
                {this.state.stampCards
                  .filter((el) => el.is_global && el.is_published)
                  .map((stampCard, stampIndex) => {
                    return (
                      <div
                        key={stampIndex}
                        style={{
                          backgroundColor: stampCard?.background_color,
                          color: stampCard?.text_color,
                        }}
                        className="stamps-wrapper"
                      >
                        <div className="stamps-box-header">
                          <Subtitle className='bold'>{__(stampCard?.title)}</Subtitle>
                          <IonIcon
                            onClick={() => {
                              this.setState({
                                stampCardModalOpen: true,
                                stampCardData: stampCard,
                              });
                            }}
                            icon={informationCircleOutline}
                          />
                        </div>

                        <div
                          className="stamps-box"
                          style={{
                            gridTemplateColumns: this.getNumberOfRowsAndColumns(
                              stampCard?.stamps_required,
                            ).columns,
                            gridAutoRows: this.getNumberOfRowsAndColumns(stampCard?.stamps_required)
                              .rows,
                          }}
                        >
                          {Array(stampCard?.stamps_required)
                            .fill(null)
                            .map((item, index) => {
                              return (
                                <div
                                  style={
                                    index <
                                      (profile?.stamp_cards_json || []).filter(
                                        (item) => item.id === stampCard.id,
                                      )[0]?.stamp_balance
                                      ? {
                                        boxShadow: `inset 0px 0px 0px 4px ${stampCard?.background_color}`,
                                      }
                                      : {
                                        border: `4px solid ${stampCard?.background_color}`,
                                      }
                                  }
                                  key={index}
                                  className={
                                    index <
                                      (profile?.stamp_cards_json || []).filter(
                                        (item) => item.id === stampCard.id,
                                      )[0]?.stamp_balance
                                      ? 'stamp-photo redeemable-stamp-photo'
                                      : 'stamp-photo locked-stamp-photo'
                                  }
                                >
                                  <img
                                    alt="stamp"
                                    style={{
                                      transform: `rotate(${this.state.stampsPosition[stampIndex][index].rotate}deg)`,
                                      top: this.state.stampsPosition[stampIndex][index].top,
                                      left: this.state.stampsPosition[stampIndex][index].left,
                                    }}
                                    src={`data:image/svg+xml;base64,${stampCard?.stamp_svg}`}
                                  />
                                </div>
                              );
                            })}
                        </div>
                        <Subtitle className=''>{__(stampCard?.description)}</Subtitle>
                      </div>
                    );
                  })}{' '}
              </div>
            )}
            {getConfig().flags.hasMilestoneRewards && rewards.length > 0 && (
              <>
                <div className="loyalty-content">
                  <MilestoneRewards lifetime_balance={profile.milestone_balance} rewards={rewards} qr_code={qr_code} __={__} />
                </div>
                {voucherRes.filter((voucher) => voucher.voucher_type == 'milestone').left > 0 &&
                  <div className="box-wrapper default-margin">
                    {voucherRes &&
                      voucherRes
                        .filter((voucher) => voucher.voucher_type == 'milestone')
                        .map((voucher, index) => {
                          return <VoucherListItem key={'vc-' + index} {...voucher} __={__} action={this.viewVoucher} />;
                        })}
                  </div>
                }


              </>
            )}
            {getConfig().flags.hasUnlockRewards && rewards.length > 0 && (
              <>
                <div className="loyalty-content">
                  <UnlockRewardItems available_balance={profile.available_balance} rewards={rewards} qr_code={qr_code} __={__} />
                </div>
                {voucherRes.filter((voucher) => voucher.voucher_type == 'unlock_reward').left > 0 &&
                  <div className="box-wrapper default-margin">
                    {voucherRes &&
                      voucherRes
                        .filter((voucher) => voucher.voucher_type == 'unlock_reward')
                        .map((voucher, index) => {
                          return <VoucherListItem key={'vc-' + index} {...voucher} __={__} action={this.viewVoucher} />;
                        })}
                  </div>
                }

              </>
            )}
            {getConfig().flags.hasVouchers &&
              <>
                <VoucherCodeInput className='redeem-voucher-field-wrapper' />
                <div className="loyalty-vouchers-wrapper">
                  <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
                  <>
                    {voucherRes.length > 0 && <Subtitle className=" loyalty-heading primary-color">{__('Your vouchers')}</Subtitle>}
                    {voucherRes &&
                      voucherRes
                        .filter((voucher) => voucher.voucher_type == 'voucher')
                        .map((voucher, index) => {
                          return <VoucherItem key={'vc-' + index} {...voucher} __={__} action={this.viewVoucher} />;
                        })}
                  </>
                </div>
              </>
            }

            <div className="loyalty-history">
              {transactionHistory.length > 0 && (
                <>
                  <Subtitle className="loyalty-heading primary-color">
                    {__('Latest transactions')}
                  </Subtitle>
                  <div className="transactions-wrapper box-wrapper">
                    {transactionHistory.slice(0, 10).map((el, index) => {
                      return (
                        <div key={index} className="small-padding ">
                          <IonRow key={index}>
                            <IonCol
                              size="8"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <NormalText className="primary-color block ">{__(getLabel(el))}</NormalText>
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <SmallText style={{ marginRight: '4px' }}>{Basket.getDate(el.transaction_date.replace('Z', '')).format(`ddd DD MMM YYYY [${__('at')}]`)}</SmallText>
                                <SmallText>{Basket.getDate(el.transaction_date.replace('Z', '')).format('LT')}</SmallText>
                              </div>
                            </IonCol>
                            <IonCol
                              ize="4"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                              }}
                            >
                              <div className="transaction-points-wrapper">
                                <NormalText className={el.is_vouchers ? 'bold' : el.stamp_power < 0 ? 'bold danger-color' : 'bold success-color'}>
                                  {el.is_vouchers ? (
                                    <>{__(el.reason_balance)}</>
                                  ) : (
                                    <>
                                      {el.stamp_power < 0 ? '-' : '+'}
                                      {Math.abs(el.stamp_power)}
                                    </>
                                  )}
                                </NormalText>
                                {el.is_stamps ? (
                                  <SmallText>{Math.abs(el.stamp_power) == 1 ? __('stamp') : __('stamps')}</SmallText>
                                ) : el.is_points ? (
                                  <SmallText>{Math.abs(el.stamp_power) == 1 ? __('point') : __('points')}</SmallText>
                                ) : null}


                              </div>
                            </IonCol>
                          </IonRow>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              <Spacer size={1} />
              {transactionHistory.length > 10 && (
                <IonButton
                  fill="clear"
                  className="link underlined"
                  color="secondary"
                  expand="block"
                  onClick={() => forwardTo('/history')}
                >
                  {__('View all history')}
                </IonButton>
              )}
            </div>
          </div>
        </Layout>
        <Modal
          className="loyalty-faq-modal"
          isOpen={this.state.stampCardModalOpen}
          onDidDismiss={() => {
            this.setState({ stampCardModalOpen: false });
          }}
        >
          <div className="lefted">
            <Title>{this.state.stampCardData?.title}</Title>
            <Spacer size={1} />
            <NormalText
              dangerouslySetInnerHTML={{
                __html: this.state.stampCardData?.stamp_content,
              }}
            ></NormalText>
          </div>
        </Modal>
        <Modal
          className="loyalty-faq-modal"
          isOpen={loyaltyFaqModalOpened}
          onDidDismiss={() => {
            this.setState({ loyaltyFaqModalOpened: false });
          }}
        >
          <div className="lefted">
            <Title>{__('Faq & Support')}</Title>
            <div dangerouslySetInnerHTML={{ __html: faq }}></div>
          </div>{' '}
        </Modal>
        <Modal
          className="loyalty-faq-modal"
          isOpen={loyaltyFaqModalOpened}
          onDidDismiss={() => {
            this.setState({ loyaltyFaqModalOpened: false });
          }}
        >
          <div className="lefted">
            <Title>{__('Faq & Support')}</Title>
            <div dangerouslySetInnerHTML={{ __html: faq }}></div>
          </div>{' '}
        </Modal>

        <Modal
          className="voucher-modal"
          isOpen={selectedVoucher !== null}
          onDidDismiss={() => {
            this.setState({ selectedVoucher: null });
            this.props.history.replace({ state: {} });
          }}
          showBackArrow={true}
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div style={{ textAlign: 'center' }}>
              <Title>{`${__(`${selectedVoucher.reward.group || 'Voucher'} details`)}`}</Title>
              <VoucherItem {...selectedVoucher} __={__} />
              <Spacer size={1} />
              <>
                {isDefined(qr_code) ? (
                  <div className="qr-holder">
                    <QRCodeCanvas value={qr_code} size={150} />
                  </div>
                ) : (
                  <div className="noQrCode">
                    <h5>{__('NO QR CODE')}</h5>
                  </div>
                )}
              </>
              <Spacer size={1} />
              <div className="voucher-modal-content">
                <NormalText className="block">{__('Voucher info')}</NormalText>
                <Spacer size={1} />
                {selectedVoucher.reward.description ? (
                  <div>
                    <NormalText>
                      <div dangerouslySetInnerHTML={{ __html: selectedVoucher.reward.description }} />
                    </NormalText>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { rewards } = state.restaurants;
  const { history } = state.orders;
  const { clientProfile } = state.common;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}`;
  }
  return {
    screenName,
    profile,
    rewards: rewards || [],
    transactionHistory: history || [],
    faq: state.common.faq || null,
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
    clientProfile,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Loyalty)));
